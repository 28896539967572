import { Component, OnInit, Inject } from '@angular/core';
import {  MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeUrl } from "@angular/platform-browser"
import { AhorrosModel } from 'app/models/ahorros';
import { AhorrosRequest } from 'app/models/request/ahorros-request';
import { ConveniosRequest } from 'app/models/request/convenios-request';
import { SocioExtend } from 'app/models/socio-extend';
import { AhorrosService } from 'app/services/ahorros.service';
import { LoginService } from 'app/services/login.service';
import { formatearDinero } from 'app/shared/constants';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-dialog-rq',
  templateUrl: './dialog-qr.component.html',
  styleUrls: ['./dialog-qr.component.scss']
})
export class DialogoQrComponent implements OnInit {
  public qrCodeSrc!: SafeUrl
   
  listadoCuentasCargo: AhorrosModel[] = [];
  lista: any;
  viewQr:boolean=false;
  icon:string='./assets/icons/icon-72x72.png';

  socioExtendLogeado: SocioExtend = new SocioExtend();
  constructor(
    @Inject(MAT_DIALOG_DATA) public editData: any,
    public dialogRef: MatDialogRef<DialogoQrComponent>,
    private sanitizer:DomSanitizer,

   
    private _ahorrosService: AhorrosService,
    private _loginService: LoginService,
    private _ngxSpinnerService: NgxSpinnerService,    
  ) { }

  ngOnInit(): void {
    this.socioExtendLogeado = this._loginService.obtenerSocioLogeado();
    this. listarCuentasCargoyConvenios();
  }

  listarCuentasCargoyConvenios() {

    let ahorrosRequest: AhorrosRequest = new AhorrosRequest();
    ahorrosRequest.codigo = this.socioExtendLogeado.codigo;
    ahorrosRequest.token = this._loginService.token;

  

    this._ngxSpinnerService.show();

    forkJoin([
        this._ahorrosService.listarCuentasAhorros(ahorrosRequest)
    ])
        //
        .subscribe(
            (response) => {
                if (response[0].body.codigo == null) {
                    this.listadoCuentasCargo =response[0].body.cuentas;
                

                    this.lista =   this.listadoCuentasCargo.filter((element) => element.esOrigen!=false);

                    this.viewQr=true;
                    console.log("this.listadoCuentasCargo", this.listadoCuentasCargo )
                        console.log("lista",this.lista)
                }

              

               
            },
            (exception) => {}
        )
        .add(() => {
            this._ngxSpinnerService.hide();
        });
}

   
    // Re-enable, when a method to download images has been implemented
    onChangeURL(url: SafeUrl) {
      this.qrCodeSrc = url;
    }
  
    saveAsImage(parent: any) {
     
      let parentElement = null
  
      console.log("parent",parent)
  
        parentElement = parent.qrcElement.nativeElement.querySelector("img").src
  
  
        if (parentElement) {
          // converts base 64 encoded image to blobData
          let blobData = this.convertBase64ToBlob(parentElement)
          // saves as image
          const blob = new Blob([blobData], { type: "image/png" })
          const url = window.URL.createObjectURL(blob)
          this.qrCodeSrc = this.sanitizer.bypassSecurityTrustUrl(url);
          console.log(this.qrCodeSrc)
          const link = document.createElement("a")
          link.href = url
          // name of the file
          link.download ="num_cuenta_ahorro_"+ this.lista[0]?.idAhorro
          link.click()
        }
      }
  
  
    private convertBase64ToBlob(Base64Image: string) {
      // split into two parts
      const parts = Base64Image.split(";base64,")
      // hold the content type
      const imageType = parts[0].split(":")[1]
      // decode base64 string
      const decodedData = window.atob(parts[1])
      // create unit8array of size same as row data length
      const uInt8Array = new Uint8Array(decodedData.length)
      // insert all character code into uint8array
      for (let i = 0; i < decodedData.length; ++i) {
        uInt8Array[i] = decodedData.charCodeAt(i)
      }
      // return blob image after conversion
      return new Blob([uInt8Array], { type: imageType })
    }

    

  cancelar() {
    this.dialogRef.close(false);
  }
 

}
