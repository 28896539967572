import { HttpClient, HttpResponse } from '@angular/common/http';
import { ConfigService } from './config.service';
import { GenericResponse } from './../models/generic-response';

import { Router } from '@angular/router';
import { Usuario } from './../models/usuario';
import { SocioExtendResponse } from './../models/socio-extend-response';
import { Observable, throwError, Subject } from 'rxjs';
import { Injectable, OnInit } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
 
import { SocioExtend } from 'app/models/socio-extend';
 
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  //private url = `${HOST_BACKEND}/api/formateador`;
  //private url= this._configService.getConfig()

  private _token: string = null;
  public socioExtend: SocioExtend = new SocioExtend();

  private validToken = new Subject<boolean>();

  constructor(
    private _httpClient: HttpClient,
    private _router: Router,
    private _configService: ConfigService
  ) {


    /*
    this._configService.getConfig().subscribe( (data)=>{
      console.log(data);
    } )*/

  }


  public get token(): string {
    let tokenStorage = sessionStorage.getItem('token');
    if (this._token != null) {
      return this._token;
    }
    else if (this._token == null && tokenStorage != null) {
      this._token = tokenStorage;
      return this._token;
    }
    return null;
  }


  iniciarSesion(usuario: Usuario): Observable<HttpResponse<SocioExtendResponse>> {
    return this._configService.getConfig().pipe(switchMap((config: any) => {
      return this._httpClient.post<SocioExtendResponse>(`${config.url_backend}/api/formateador/login`, usuario, { observe: 'response' })
    }))
  }


  // NO funciona por cambio de backend
  validarToken(token: String): Observable<boolean> {
    let objToken: any = {};
    objToken.key = token;
    return this._configService.getConfig().pipe(switchMap((config: any) => {
      return this._httpClient.post<boolean>(`${config.url_backend}/api/formateador/validar`, objToken)
    }))
  }



  verificarAutenticacion(): boolean {
    // console.log('Verificar autenticacion ')
    //console.log(this.token);
    if (this.token != null) {
      //console.log('return true');
      return true;
    } else {
      console.log("this.token",this.token)
      //  console.log('return false');
      return false;
    }
  }

  guardarToken(socioExtendResponse: SocioExtendResponse): void {

    let today = new Date();
    let expiresValue = new Date(today);

    this._token = socioExtendResponse.data.token;
    sessionStorage.setItem('token', socioExtendResponse.data.token);
    sessionStorage.setItem('socio', JSON.stringify(socioExtendResponse.data));
    // sessionStorage.

    // console.log(expiresValue.setMinutes(today.getMinutes() + 5))
    // response.data.fechaexpira = expiresValue.setMinutes(today.getMinutes() + 5);

  }


  obtenerPinPad(): Observable<any> {
    return this._httpClient.get('./assets/json/listPinpad.json')
  }

  cerrarSesion() {
    this._token = null;
    sessionStorage.clear();
    this._router.navigate(['/pages/login'])
    // window.location.reload();
  }

  limpiarSessionStorage() {
    this._token = null;
    sessionStorage.clear();
  }

  obtenerSocioLogeado(): SocioExtend {
    if (sessionStorage.getItem('socio') != null) {
      this.socioExtend = JSON.parse(sessionStorage.getItem('socio'))
    } return this.socioExtend;
  }



  getValidToken() {
    return this.validToken.asObservable();
  }
  setValidToken(isValid: boolean) {
    this.validToken.next(isValid);
  }


  //Validar Clave de 4 dígitos
  validarPin(usuario: Usuario): Observable<HttpResponse<GenericResponse>> {
    return this._configService.getConfig().pipe(switchMap((config: any) => {
      return this._httpClient.post<GenericResponse>(`${config.url_backend}/api/formateador/validaPin`, usuario, { observe: 'response' })
    }))
  }

  creaPin(usuario: Usuario): Observable<HttpResponse<GenericResponse>> {
    return this._configService.getConfig().pipe(switchMap((config: any) => {
      return this._httpClient.post<GenericResponse>(`${config.url_backend}/api/formateador/creaPin`, usuario, { observe: 'response' })
    }))
  }
}
