export class SocioExtend{
    nroTarjeta: string;
    pin: string;
    codigo: string;
    nombres: string;
    email:string;
    success: boolean;
    mensaje: string;
    mensajes: string;
    token: string;
    dni?: string;
    agencia?:string;

    fechaexpiracion: any;
}