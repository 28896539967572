import { GenericResponse } from './../generic-response';
import { extend } from "lodash";

export class TipoCambioResponse extends GenericResponse{
    monedaOrigen:string;
    monedaDestino: string;
    tipoCambioCompra: number;
    tipoCambioVenta: number;

    tipoCambioCompraFormat: string;
    tipoCambioVentaFormat:string;
}