import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSharedModule } from '@fuse/shared.module';

import { FooterComponent } from 'app/layout/components/footer/footer.component';

import {MatTooltipModule} from '@angular/material/tooltip'; 

import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { DialogoQrComponent } from './dialogopagoservicio/dialog-qr.component';
import { QRCodeModule } from 'angularx-qrcode';
import { SlickCarouselModule } from 'ngx-slick-carousel';
@NgModule({
    declarations: [
        FooterComponent,DialogoQrComponent
    ],
    imports     : [
        RouterModule,

        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatTooltipModule,
        FuseSharedModule,
        MatDialogModule,
        QRCodeModule,
        SlickCarouselModule
    ],
    exports     : [
        FooterComponent
    ]
})
export class FooterModule
{
}
