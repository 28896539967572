import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "home",
        title: "Inicio",
        // translate: 'NAV.SAMPLE.TITLE',
        type: "item",
        icon: "home",
        url: "/home",
    },

    {
        id: "operaciones",
        title: "Perfil",
        //translate: 'NAV.APPLICATIONS',
        type: "group",
        children: [
            {
                id: "transferencias",
                title: "Mi Perfil",
                // translate: 'NAV.SAMPLE.TITLE',
                type: "collapsable",
                icon: "person_add",
                children: [
                    
                    {
                        id: "cuenta-tercero-confianza",
                        title: "Mis Datos",
                        type: "item",
                        icon: "person",
                        url: "/pages/perfil",
                    },
                    {
                        id: "cuenta-tercero-confianza",
                        title: "Cambiar Operador",
                        type: "item",
                        icon: "smartphone",
                        url: "/pages/cambiar-operador",
                    },
                    {
                        id: "cuenta-propia",
                        title: "Cambiar Correo",
                        type: "item",
                        icon: "email",
                        url: "/pages/cambiar-correo",
                        // icon     : 'compare_arrows'
                    },
                     /*
                    {
                        id: "cuenta-tercero",
                        title: "Estado de Cuenta",
                        type: "item",
                        icon: "account_balance",
                        url: "/pages/estado-cuenta",
                    },
                   
                    {
                        id: "cuenta-tercero",
                        title: "Bloquear Tarjeta",
                        type: "item",
                        icon: "credit_card",
                        url: "pages/bloquear-tarjeta",
                    },*/
                ],
            },
            {
                id: "pago-servicios",
                title: "Simulador",
                type: "item",
                icon: "monetization_on",
                url: "/pages/simulador",
            },
            {
                id: "favorito",
                title: "Mis favoritos y socio confianza",
                type: "item",
                icon: "start",
                url: "/pages/favorito",
            }
        ],
    },

    {
        id: "operaciones",
        title: "Operaciones",
        //translate: 'NAV.APPLICATIONS',
        type: "group",
        children: [ 
            /*
            {
                id: "operaciones",
                title: "Operaciones",
                type: "item",
                icon: "attach_money",
                url: "/operaciones/realizar-operaciones",
            },*/
            {
                id: "pagos",
                title: "Pagos",
                type: "item",
                icon: "attach_money",
                url: "/pagos/pagos",
            },
            {
                id: "transferencias",
                title: "transferencias",
                type: "item",
                icon: "compare_arrows",
                url: "/transferencias/transferencias",
            },
            /*
            {
                id: "pago-servicios",
                title: "Pago de Servicios",
                type: "item",
                icon: "attach_money",
                url: "/pagos/servicios",
            },*/
 /*
            {
                id: "transferencias",
                title: "Transferencias",
                // translate: 'NAV.SAMPLE.TITLE',
                type: "collapsable",
                icon: "compare_arrows",
                children: [
                    {
                        id: "cuenta-propia",
                        title: "A Cuenta Propia",
                        type: "item",
                        url: "/transferencias/cuenta-propia",
                        // icon     : 'compare_arrows'
                    },
                    {
                        id: "cuenta-tercero-confianza",
                        title: "A Socios de Confianza",
                        type: "item",
                        url: "/transferencias/cuenta-tercero-confianza",
                    },
                    {
                        id: "cuenta-tercero",
                        title: "A Otros Socios",
                        type: "item",
                        url: "/transferencias/cuenta-tercero",
                    },
                ],
            }, */
            /*

            {
                id: "pago",
                title: "Pago de Prestamos",
                // translate: 'NAV.SAMPLE.TITLE',
                type: "collapsable",
                icon: "attach_money",
                children: [
                    {
                        id: "pago-prestamos",
                        title: "A Cuenta Propia",
                        type: "item",
                        url: "/pagos/prestamos",
                        // icon     : 'compare_arrows'
                    },
                    {
                        id: "ptc-prestamos",
                        title: "A Socios de Confianza",
                        type: "item",
                        url: "/pagos/tercero-confianza/prestamos",
                        // icon     : 'compare_arrows'
                    },

                    {
                        id: "pt-prestamos",
                        title: "A Otros Socios",
                        type: "item",
                        url: "/pagos/tercero/prestamos",
                        // icon     : 'compare_arrows'
                    },
                ],
            },

            {
                id: "pago",
                title: "Pago de Aportes",
                // translate: 'NAV.SAMPLE.TITLE',
                type: "collapsable",
                icon: "attach_money",
                children: [
                    {
                        id: "pago-aporte",
                        title: "A Cuenta Propia",
                        type: "item",
                        url: "/pagos/aportes",
                    },

                    {
                        id: "ptc-aporte",
                        title: "A Socios de Confianza",
                        type: "item",
                        url: "/pagos/tercero-confianza/aportes",
                    },
                    {
                        id: "pt-aporte",
                        title: "A Otros Socios",
                        type: "item",
                        url: "/pagos/tercero/aportes",
                    },
                ],
            },

            {
                id: "pago",
                title: "Pago de FPS",
                // translate: 'NAV.SAMPLE.TITLE',
                type: "collapsable",
                icon: "attach_money",
                children: [
                    {
                        id: "pago-fps",
                        title: "A Cuenta Propia",
                        type: "item",
                        url: "/pagos/fps",
                    },
                    {
                        id: "ptc-fps",
                        title: "A Socios de Confianza",
                        type: "item",
                        url: "/pagos/tercero-confianza/fps",
                    },
                    {
                        id: "pt-fps",
                        title: "A Otros Socios",
                        type: "item",
                        url: "/pagos/tercero/fps",
                    },
                ],
            },

            */
        ],
    },


    {
        id: "consultas",
        title: "Consultas",
        //translate: 'NAV.APPLICATIONS',
        type: "group",
        children: [
            {
                id: "consultas-cuentas",
                title: "Mis Cuentas",
                type: "item",
                icon: "account_balance_wallet",
                url: "/consultas/cuentas",
            },
            {
                id: "consultas-aportes",
                title: "Mis aportes",
                type: "item",
                icon: "monetization_on",
                url: "/consultas/aportes",
            },
            {
                id: "consultas-prestamos",
                title: "Mis Préstamos",
                type: "item",
                icon: "money",
                url: "/consultas/prestamos",
            },
            {
                id: "consultas-fps",
                title: "Fondo de Prevision Social",
                type: "item",
                icon: "security",
                url: "/consultas/fps",
            },
        ],
    },


    {
        id: "salir",
        title: "Cerrar Sesión",
        //translate: 'NAV.APPLICATIONS',
        type: "group",
        children: [
            {
                id: "login",
                title: "Cerrar Sesión",
                // translate: 'NAV.SAMPLE.TITLE',
                type: "item",
                icon: "exit_to_app",
                url: "/pages/login",
            },
        ],
    },
];
