// Constantes en desarrollo local
// export const HOST_BACKEND = `http://localhost:8080`;   // Local 

// Formateador 
//export const HOST_BACKEND = `http://10.0.7.104:8092`;   // Local 
//export const HOST_BACKEND = `https://testydesarrollo.coopsantamaria.com.pe:55555`;
//export const HOST_BACKEND = `http://10.0.7.105:8092`;   // Local 



// Codigos de Error
export const CODIGO_ERROR_NO_AUTENTICADO = 'JSR-105';
export const MENSAJE_NO_AUTENTICADO = 'No Autenticado o Session Expirada';
export const MENSAJE_NO_AUTENTICADO2 = 'No Autenticado';

//Mensajes
export const MSJ = {
   CAMPO_OBLIGATORIO: 'Campo Obligatorio',
   CTAS_NO_PUEDEN_SER_IGUALES: 'La Cuenta Origen y Destino NO pueden ser iguales',
   ADVERTENCIA: 'Espere!',
   EEROR:'Error',
   SALDO_INSUFICIENTE_PAGO: 'No tiene saldo suficiente para realizar el pago',
   SALDO_INSUFICIENTE_TRANSFERENCIA: 'No tiene saldo suficiente para realizar la transferencia',
   NO_TIENE_PAGO_PENDIENTE: 'No tiene pago pendiente',
   DEBE_SELECCIONAR_PERIODO_MAS_aNTIGUO:'Debe de selecionar el periodo más antiguo, y tiene que ser consecutivos'
}


//Funciones de apoyo
export const formatearDinero = function (valor: number, tipo: string) {
   let currencyFormatter: any = require('currency-formatter')
   let configCurrency: any = {
      symbol: 'S/',
      decimal: '.',
      thousand: ',',
      precision: 2,
      format: '%s %v' // %s is the symbol and %v is the value
   }
   if (tipo == 'SOLES') {
      return currencyFormatter.format(valor, configCurrency);
   } else if (tipo == 'DOLARES') {
      return currencyFormatter.format(valor, { code: 'USD' });
   } else {
      return currencyFormatter.format(valor, configCurrency);
   }
}


export const obtenerSimboloPorDescripcion = function (tipo: string) {

   if (tipo == 'SOLES') {
      return "S/ ";
   } else if (tipo == 'DOLARES') {
      return "$";
   }
}

export const keyCaptcha=  '6Lfy-akkAAAAAOKUmblx6_h1SVXOD-Z4F1riLY8C'