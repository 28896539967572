import { TipoCambioResponse } from './../models/response/tipo-cambio-response';
import { switchMap } from 'rxjs/operators';
import { TipoCambioRequest } from './../models/request/tipo-cambio-request';
import { ConfigService } from './config.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DatosCuentaRequest } from 'app/models/request/datos-cuenta-request';
import { DatosCuentaResponse } from 'app/models/response/datos-cuenta-response';
import { TokenSocioTrxRequest } from 'app/models/request/token-socio-trx-request';
import { TokenSocioTrxResponse } from 'app/models/response/token-socio-trx-response';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
  constructor(
    private _httpClient: HttpClient,
    private _configService: ConfigService
  ) { }

  obtenerTipoCambioVigente(tipoCambioRequest: TipoCambioRequest): Observable<HttpResponse<TipoCambioResponse>> {
    return this._configService.getConfig().pipe(switchMap((config: any) => {
      return this._httpClient.post<TipoCambioResponse>(`${config.url_backend}/api/formateador/tipoCambio`, tipoCambioRequest, { observe: 'response' })
    }))
  }

  // ObtenerDatos cuenta por nro de cuenta
  obtenerDatosCuenta(datosCuentaRequest: DatosCuentaRequest): Observable<HttpResponse<DatosCuentaResponse>> {
    return this._configService.getConfig().pipe(switchMap((config: any) => {
      return this._httpClient.post<DatosCuentaResponse>(`${config.url_backend}/api/formateador/datos-cuenta`, datosCuentaRequest, { observe: 'response' })
    }))
  }
  // ObtenerDatos cuenta por nro de prestamo
  obtenerDatosCuentaPorNroPrestamo(datosCuentaRequest: DatosCuentaRequest): Observable<HttpResponse<DatosCuentaResponse>> {
    return this._configService.getConfig().pipe(switchMap((config: any) => {
      return this._httpClient.post<DatosCuentaResponse>(`${config.url_backend}/api/formateador/datos-prestamo`, datosCuentaRequest, { observe: 'response' })
    }))
  }

  // ObtenerDatos cuenta por nro de DNI
  obtenerDatosCuentaPorNroDni(datosCuentaRequest: DatosCuentaRequest): Observable<HttpResponse<DatosCuentaResponse>> {
    return this._configService.getConfig().pipe(switchMap((config: any) => {
      return this._httpClient.post<DatosCuentaResponse>(`${config.url_backend}/api/formateador/datos-socio`, datosCuentaRequest, { observe: 'response' })
    }))
  }

  // Obtener Token para transferenciias y pagos a terceros
  obtenerTokenTransferencias(tokenSocioTrxRequest: TokenSocioTrxRequest): Observable<HttpResponse<TokenSocioTrxResponse>> {
    return this._configService.getConfig().pipe(switchMap((config: any) => {
      return this._httpClient.post<TokenSocioTrxResponse>(`${config.url_backend}/api/formateador/crear-socio-token`, tokenSocioTrxRequest, { observe: 'response' })
    }))
  }

}
