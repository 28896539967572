// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `content {
  position: relative;
  display: flex;
  z-index: 1;
  flex: 1 0 auto;
}
content > *:not(router-outlet) {
  display: flex;
  flex: 1 0 auto;
  width: 100%;
  min-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/components/content/content.component.scss","webpack://./../../../PROYECTO%20COOP/FRONT/web-frontend/src/app/layout/components/content/content.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,UAAA;EACA,cAAA;ACCJ;ADCI;EACI,aAAA;EACA,cAAA;EACA,WAAA;EACA,eAAA;ACCR","sourcesContent":["content {\r\n    position: relative;\r\n    display: flex;\r\n    z-index: 1;\r\n    flex: 1 0 auto;\r\n\r\n    > *:not(router-outlet) {\r\n        display: flex;\r\n        flex: 1 0 auto;\r\n        width: 100%;\r\n        min-width: 100%;\r\n    }\r\n}","content {\n  position: relative;\n  display: flex;\n  z-index: 1;\n  flex: 1 0 auto;\n}\ncontent > *:not(router-outlet) {\n  display: flex;\n  flex: 1 0 auto;\n  width: 100%;\n  min-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
