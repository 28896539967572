import {
    Directive,
    ElementRef,
    HostListener,
    Input
  } from "@angular/core";
  import { NgControl } from '@angular/forms';
  
  
  @Directive({
    selector: '[OnlyNumbersTwoDecimals]'
  })
  export class OnlyNumbersTwoDecimals {
   //private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
   private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
 //   private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

    constructor(private el: ElementRef) {
    }
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
     // console.log(this.el.nativeElement.value);
      // Allow Backspace, tab, end, and home keys
      if (this.specialKeys.indexOf(event.key) !== -1) {
        return;
      }
      let current: string = this.el.nativeElement.value;
      const position = this.el.nativeElement.selectionStart;
      const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
      if (next && !String(next).match(this.regex)) {
        event.preventDefault();
      }
    }
  }



  @Directive({
    selector: 'input[type=number], input[numbersOnly]'
  })
  export class NumbersOnlyInputDirective {
  
    constructor(private elRef: ElementRef) { }
  
    @HostListener('input', ['$event']) onInputChange(event) {
      const initalValue = this.elRef.nativeElement.value;
      this.elRef.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
      if ( initalValue !== this.elRef.nativeElement.value) {
        event.stopPropagation();
      }
    }
  
  }