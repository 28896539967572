import { FuseDemoModule } from './../../../@fuse/components/demo/demo.module';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FuseHighlightModule } from './../../../@fuse/components/highlight/highlight.module';
import {  CountdownGlobalConfig } from 'ngx-countdown';
import { SharedModule } from './../../shared/shared.module';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations'; //<-- copy this.
import { MatIconModule } from '@angular/material/icon';
import { AuthGuard } from './../../shared/guard/auth-guard';
import { HomeComponent } from './home.component';
import { FuseSharedModule } from './../../../@fuse/shared.module';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
//import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AlertaComponent } from '../operaciones/alerta/alerta.component';
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
const routes = [
    {
        path: 'home',
        component: HomeComponent,canActivate: [AuthGuard]
    },
    
];



@NgModule({
    declarations: [
        HomeComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTabsModule,

       // NgxChartsModule,

        FuseSharedModule,
        SharedModule,
        FuseDemoModule,
        //CountdownModule,
        MatDialogModule,

        FuseHighlightModule
    ],
    exports: [
        HomeComponent
    ],
   /* providers:[
        { provide: CountdownGlobalConfig, useFactory: countdownConfigFactory }
    ]*/
})

export class HomeModule {
}
/*
function countdownConfigFactory(): any {
    return {leftTime:100 ,format: 'mm:ss' };
  }*/