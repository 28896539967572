// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.folded:not(.unfolded) :host .nav-link > span {
  opacity: 0;
  transition: opacity 200ms ease;
}
.folded:not(.unfolded) :host.open .children {
  display: none !important;
}
:host .nav-link .collapsable-arrow {
  transition: transform 0.3s ease-in-out, opacity 0.25s ease-in-out 0.1s;
  transform: rotate(0);
}
:host > .children {
  overflow: hidden;
}
:host.open > .nav-link .collapsable-arrow {
  transform: rotate(90deg);
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/navigation/vertical/collapsable/collapsable.component.scss","webpack://./../../../PROYECTO%20COOP/FRONT/web-frontend/src/@fuse/components/navigation/vertical/collapsable/collapsable.component.scss"],"names":[],"mappings":"AAMY;EACI,UAAA;EACA,8BAAA;ACLhB;ADWY;EACI,wBAAA;ACThB;ADgBQ;EACI,sEAAA;EACA,oBAAA;ACdZ;ADkBI;EACI,gBAAA;AChBR;ADuBY;EACI,wBAAA;ACrBhB","sourcesContent":[":host {\r\n\r\n    .folded:not(.unfolded) & {\r\n\r\n        .nav-link {\r\n\r\n            > span {\r\n                opacity: 0;\r\n                transition: opacity 200ms ease;\r\n            }\r\n        }\r\n\r\n        &.open {\r\n\r\n            .children {\r\n                display: none !important;\r\n            }\r\n        }\r\n    }\r\n\r\n    .nav-link {\r\n\r\n        .collapsable-arrow {\r\n            transition: transform .3s ease-in-out, opacity .25s ease-in-out .1s;\r\n            transform: rotate(0);\r\n        }\r\n    }\r\n\r\n    > .children {\r\n        overflow: hidden;\r\n    }\r\n\r\n    &.open {\r\n\r\n        > .nav-link {\r\n\r\n            .collapsable-arrow {\r\n                transform: rotate(90deg);\r\n            }\r\n        }\r\n    }\r\n}\r\n",".folded:not(.unfolded) :host .nav-link > span {\n  opacity: 0;\n  transition: opacity 200ms ease;\n}\n.folded:not(.unfolded) :host.open .children {\n  display: none !important;\n}\n:host .nav-link .collapsable-arrow {\n  transition: transform 0.3s ease-in-out, opacity 0.25s ease-in-out 0.1s;\n  transform: rotate(0);\n}\n:host > .children {\n  overflow: hidden;\n}\n:host.open > .nav-link .collapsable-arrow {\n  transform: rotate(90deg);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
