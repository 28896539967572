export class GenericResponse {
    /* token:string;
     tokenExpired:number;
     codigo:string;
     mensaje:string;
     error:string;*/
    codigo: string;
    mensaje: string;
    success: boolean;
    token: string;

}