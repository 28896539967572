import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-spinner-global',
  templateUrl: './spinner-global.component.html',
  styleUrls: ['./spinner-global.component.scss']
})
export class SpinnerGlobalComponent implements OnInit {

  spinnerConfig: any;

  constructor(private spinner: NgxSpinnerService) { }

  ngOnInit(): void {



    // bdColor="rgb(56,142,60)" size="large" color="#ffffff" type="ball-clip-rotate-pulse" [fullScreen]="true" 

  }

}
