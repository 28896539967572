import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DialogoQrComponent } from './dialogopagoservicio/dialog-qr.component';
import { SocioExtend } from 'app/models/socio-extend';
import { LoginService } from 'app/services/login.service';

@Component({
    selector   : 'footer',
    templateUrl: './footer.component.html',
    styleUrls  : ['./footer.component.scss']
})
export class FooterComponent implements OnInit
{
    fechaActual;
    socioExtendLogeado: SocioExtend = new SocioExtend();
       // Public
      
   
       descripcionAlerta: any[];
       nombreAlerta: string;
       mensajes!:any[];
   
       slideConfig = {
           autoplay:true, 
           slidesToScroll: 1,
           dots: false,
           infinite: false,
           speed: 3000,
           slidesToShow: 1, 
           variableWidth: false, 
           
          
       
       };
          

    /**
     * Constructor
     */
    constructor(public dialog: MatDialog,private _loginService: LoginService)
    {
    }

    ngOnInit(){
        this.fechaActual = new Date();
         
    }

   
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogoQrComponent, {

      width:"600px",  
    });

    dialogRef.afterClosed().subscribe(result => {
     
      console.log('close');
       
    });
  }

  
    /*slider */
     
    addSlide() { 
    }
    
    removeSlide() {
     
    }
    
    slickInit(e) { 
    }
    
    breakpoint(e) { 
    }
    
    afterChange(e) { 
    }
    
    beforeChange(e) { 
    }
    
//end slider


}
