import { NgxSpinnerModule } from 'ngx-spinner';
import { ModuleWithProviders, NgModule,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumbersOnlyInputDirective, OnlyNumbersTwoDecimals } from './directives/only-number.directive';
import { CurrencyMaskInputMode, NgxCurrencyModule } from "ad-ngx-currency";
//import { CountdownModule } from 'ngx-countdown';
import { SpinnerGlobalComponent } from './spinner-global/spinner-global.component';
import { TipoDeCambioComponent } from './utils/tipo-de-cambio/tipo-de-cambio.component';
//import { DigitOnlyModule } from '@uiowa/digit-only';

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  allowZero: false,
  decimal: ".",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ",",
  nullable: false,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

@NgModule({
  declarations: [
    NumbersOnlyInputDirective,
    OnlyNumbersTwoDecimals,
    SpinnerGlobalComponent,
    TipoDeCambioComponent

  ],  
  imports: [
    CommonModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxSpinnerModule,
    //DigitOnlyModule
  ],
  exports:[
    NumbersOnlyInputDirective,
    OnlyNumbersTwoDecimals,
    NgxSpinnerModule,
    //DigitOnlyModule,
    SpinnerGlobalComponent,
    TipoDeCambioComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
      return {
          ngModule: SharedModule
      };
  }
}
