export class GenericRequest {
    codigo: string;
    token: string;
    idTercero: string;

    // Agregados despues de transferencias con token por celular
    nroCuentaOrigen: string;
    nroTarjeta: string;
    codBeneficiario: string;//llenar para pago tercero
    
    tokenSocio: string;//llenar si es para tercero no confianza
    esConfianza: string;
}