import { TipoCambioRequest } from "./../../../models/request/tipo-cambio-request";
import { UtilsService } from "./../../../services/utils.service";
import { SocioExtend } from "app/models/socio-extend";
import { LoginService } from "./../../../services/login.service";
import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";

import { navigation } from "app/navigation/navigation";

import * as $ from 'jquery';
@Component({
    selector: "toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
    socioExtendLogeado: SocioExtend = new SocioExtend();
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];

    // Private
    private _unsubscribeAll: Subject<void>;

    // Public
    public socioExtend: SocioExtend = new SocioExtend();
    nombreSocio;

    descripcionAlerta: any[];
    nombreAlerta: string;
    mensajes!:any[];

    slideConfig = {
        autoplay:true, 
        slidesToScroll: 1,
        dots: false,
        infinite: false,
        speed: 2000,
        slidesToShow: 1, 
        variableWidth: false, 
        
       
    
    };
       

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _loginService: LoginService,
        private _utilsService: UtilsService
    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: "Online",
                icon: "icon-checkbox-marked-circle",
                color: "#4CAF50",
            },
            {
                title: "Away",
                icon: "icon-clock",
                color: "#FFC107",
            },
            {
                title: "Do not Disturb",
                icon: "icon-minus-circle",
                color: "#F44336",
            },
            {
                title: "Invisible",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#BDBDBD",
            },
            {
                title: "Offline",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#616161",
            },
        ];

        this.languages = [
            {
                id: "en",
                title: "English",
                flag: "us",
            },
            {
                id: "tr",
                title: "Turkish",
                flag: "tr",
            },
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.socioExtendLogeado = this._loginService.obtenerSocioLogeado(); 
        this.descripcionAlerta=[];
        if (this.socioExtendLogeado.mensajes == null) {
            this.descripcionAlerta = [
                {
                    nombre: " Realice pagos de servicios segun el convenio, unsch, seda, electrocentro",
                },
                { nombre: " Realice pagos de prestamos " },
                { nombre: " Realice pagos de aportes " },
                { nombre: " Realice depósitos de fondo de solidaridad "},
                { nombre: " Realice transferencias a otros socios "},
                { nombre: " Recibe pagos de otros socios con tu QR "},
            ];
           
        } else {
            this.mensajes = this.socioExtendLogeado.mensajes.split("|");
           
            for (let index = 0; index < this.mensajes.length; index++) {
                const element = this.mensajes[index];
                let data = { nombre: " " + element.charAt(0).toUpperCase()+element.slice(1).toLowerCase() + " " };
                this.descripcionAlerta.push(data);
            }
         
        }
 
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar =
                    settings.layout.navbar.position === "top";
                this.rightNavbar = settings.layout.navbar.position === "right";
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
            id: this._translateService.currentLang,
        });
        this.socioExtend = this._loginService.obtenerSocioLogeado();
        //this.nombreSocio = this.socioExtend.nombres.split(" ", 0);
        this.nombreSocio =
            this.socioExtend.nombres != null
                ? this.socioExtend.nombres.substring(
                      this.socioExtend.nombres.lastIndexOf(" ") + 1,
                      this.socioExtend.nombres.length
                  )
                : null;

        /*  let tipoCambioRequest:TipoCambioRequest = new TipoCambioRequest()
        tipoCambioRequest.token = this._loginService.token;
        console.log(tipoCambioRequest)*/
        /*  this._utilsService.obtenerTipoCambioVigente(tipoCambioRequest).subscribe( (e)=>{
            console.log(e)
        } )*/
    }


    /*slider */
     
    addSlide() { 
    }
    
    removeSlide() {
     
    }
    
    slickInit(e) { 
    }
    
    breakpoint(e) { 
    }
    
    afterChange(e) { 
    }
    
    beforeChange(e) { 
    }
    
//end slider






    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(void 0);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        //console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    cerrarSesion() {
        this._loginService.cerrarSesion();
    }
}
