import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment"; 
import { REMOVE_STYLES_ON_COMPONENT_DESTROY, bootstrapApplication } from "@angular/platform-browser";
import { provideRouter } from "@angular/router";
 
if (environment.production) {
    enableProdMode();
}
 

 
 
platformBrowserDynamic(   [   { provide: REMOVE_STYLES_ON_COMPONENT_DESTROY,  useValue:false  } ])
    .bootstrapModule(AppModule,)
    .catch((err) => console.error(err));

    
 /*        
    bootstrapApplication(AppModule, {
        providers: [ 
            { provide: REMOVE_STYLES_ON_COMPONENT_DESTROY,
             useValue:false
            }
             ],
      }) .catch((err) => console.error(err));
*/
 