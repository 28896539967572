// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `fuse-navigation {
  display: flex;
  flex: 1 0 auto;
}
fuse-navigation > .nav {
  margin: 0;
  padding: 0;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/@fuse/components/navigation/navigation.component.scss","webpack://./../../../PROYECTO%20COOP/FRONT/web-frontend/src/@fuse/components/navigation/navigation.component.scss"],"names":[],"mappings":"AAIA;EACI,aAAA;EACA,cAAA;ACHJ;ADKI;EACI,SAAA;EACA,UAAA;EACA,WAAA;ACHR","sourcesContent":["//@import 'src/@fuse/scss/fuse';\r\n@import '../../scss/fuse';\r\n\r\n\r\nfuse-navigation {\r\n    display: flex;\r\n    flex: 1 0 auto;\r\n\r\n    > .nav {\r\n        margin: 0;\r\n        padding: 0;\r\n        width: 100%;\r\n    }\r\n}\r\n","fuse-navigation {\n  display: flex;\n  flex: 1 0 auto;\n}\nfuse-navigation > .nav {\n  margin: 0;\n  padding: 0;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
