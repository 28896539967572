import { PagosModule } from './pagos/pagos.module';
import { TransferenciasModule } from './transferencias/transferencias.module';
import { HomeModule } from './home/home.module';
import { ConsultasModule } from './consultas/consultas.module';
import { AuthGuard } from './../shared/guard/auth-guard';
import { FuseSharedModule } from './../../@fuse/shared.module';
import { RouterModule } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';

import { OperacionesModule } from './operaciones/operacion.module';
import { PagosOtrosModule } from './pagos/pagos/pa-pagos.module';
import { TransferenciaModule } from './transferencias/transferencia/transferencias.module';



import { TransfCuentaTerceroConfianzaComponent } from "./transferencias/transferencia/tr-cuenta-tercero-confianza/tr-cuenta-tercero-confianza.component";
import { TransferenciaComponent } from "./transferencias/transferencia/transferencia.component";
import { TransfCuentaPropiaComponent } from "./transferencias/transferencia/tr-cuenta-propia/tr-cuenta-propia.component";
import { TransfCuentaTerceroComponent } from "./transferencias/transferencia/tr-cuenta-tercero/tr-cuenta-tercero.component";
import { DialogSocioConfianzaComponent } from "./transferencias/transferencia/dialog-socio-confianza/dialog-socio-confianza.component";


import { PagServiciosComponent } from "./pagos/pagos/pag-servicios.component";
import { DialogoServpagoservicioComponent } from "./pagos/pagos/pa-servicios/dialogopagoservicio/dialogopagoservicio.component";
import { PaPagosServiciosComponent } from "./pagos/pagos/pa-servicios/pa-servicios.component";
import { PaPagoAportesComponent } from "./pagos/pagos/pa-aportes/pa-aportes.component";
import { PaPagoFpsComponent } from "./pagos/pagos/pa-fps/pa-fps.component";
import { PaPagoPrestamosComponent } from "./pagos/pagos/pa-prestamos/pa-prestamos.component";
import { PaPagoPtcPrestamosComponent } from "./pagos/pagos/pa-tercero-confianza/pa-ptc-prestamos/pa-ptc-prestamos.component";
import { PaPagoPtcAportesComponent } from "./pagos/pagos/pa-tercero-confianza/pa-ptc-aportes/pa-ptc-aportes.component";
import { PaPagoPtcFpsComponent } from "./pagos/pagos/pa-tercero-confianza/pa-ptc-fps/pa-ptc-fps.component";
import { PaPagoPtPrestamosComponent } from "./pagos/pagos/pa-tercero/pa-pt-prestamos/pa-pt-prestamos.component";
import { PaPagoPtFpsComponent } from "./pagos/pagos/pa-tercero/pa-pt-fps/pa-pt-fps.component";
import { PaPagoPtAportesComponent } from "./pagos/pagos/pa-tercero/pa-pt-aportes/pa-pt-aportes.component";
import { DialogSocioConfianzaPagComponent } from "./pagos/pagos/dialog-socio-confianza/dialog-socio-confianza.component";
 
const routes = [

  {
    path: 'consultas',
    loadChildren: () => import('./consultas/consultas.module').then(m => m.ConsultasModule), canActivate: [AuthGuard]
  },

  {
    path: 'transferencias',

    loadChildren: () => import('./transferencias/transferencias.module').then(m => m.TransferenciasModule), canActivate: [AuthGuard]
  },

  {
    path: 'pagos',
    loadChildren: () => import('./pagos/pagos.module').then(m => m.PagosModule), canActivate: [AuthGuard]
  },
  
  {
    path: 'operaciones',
    loadChildren: () => import('./operaciones/operacion.module').then(m => m.OperacionesModule), canActivate: [AuthGuard]
  },

  /* {
    path      : '**',
    redirectTo: 'consultas'
}*/

];

@NgModule({
  declarations: [
     
  ],
  imports: [
    RouterModule.forChild(routes),

    FuseSharedModule,
    SharedModule, 

    HomeModule
  ],
  exports: [

  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class MainModule { }
