import { async } from '@angular/core/testing';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { LoginService } from './../../services/login.service';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { setTimeout } from 'timers';


@Injectable()
export class AuthGuard  {
  constructor(
    private _router: Router,
    private loginService: LoginService,
    private _matSnackBar: MatSnackBar,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    //console.log('Entra auth guard')


    if(this.loginService.verificarAutenticacion()){
     // this.validarToken();
     
      return true;
    }else{
      this._router.navigate(['/pages/login'])
      return false;
    }



   


    

  }

  async validarToken(){
    let token = this.loginService.token;
    await this.loginService.validarToken(token).subscribe( 
        (e)=>{
           if(e){
              // console.log('Token aun válido')
           }else{
            this._matSnackBar.open('Token inválido o caducó', 'OK', {
                verticalPosition: 'bottom',
                duration        : 2000
            });
            this.loginService.cerrarSesion();
           }
        }
     );
  }



 
  
}
