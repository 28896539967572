// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qrcodeImage {
  text-align: center;
}

/* Add custom styles here */
.center {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/components/footer/dialogopagoservicio/dialog-qr.component.scss","webpack://./../../../PROYECTO%20COOP/FRONT/web-frontend/src/app/layout/components/footer/dialogopagoservicio/dialog-qr.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;ACCJ;;ADEE,2BAAA;AACA;EACC,kBAAA;ACCH","sourcesContent":[".qrcodeImage {\r\n    text-align: center;\r\n  }\r\n  \r\n  /* Add custom styles here */\r\n  .center {\r\n   text-align: center;\r\n  }",".qrcodeImage {\n  text-align: center;\n}\n\n/* Add custom styles here */\n.center {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
