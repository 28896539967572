 
 
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { NgxSpinnerService } from "ngx-spinner";

import { Component, OnInit, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

import * as moment from "moment"; 
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { SocioExtend } from "app/models/socio-extend";
import { LoginService } from "app/services/login.service";
  

@Component({
    selector: "app-alerta",
    templateUrl: "./alerta.component.html",
    styleUrls: ["./alerta.component.scss"],
})
export class AlertaComponent implements OnInit {



    socioExtendLogeado: SocioExtend; 
    frmWhatsapp: UntypedFormGroup;
    
    slideConfig = {
        autoplay:true, 
        slidesToScroll: 1,
        dots: true,
        infinite: false,
        speed: 1000,
        slidesToShow: 1, 
        variableWidth: false
        
        /*
         centerMode: true,
        "slidesToScroll": 1,
        "dots": true,
        "infinite": true,
        "speed": 300,
        "slidesToShow": 1,
        "centerMode": true,
        "variableWidth": true*/
    
    };
    /**
     *
     * @param {MatDialogRef<AlertaComponent>} _dialogRef
     * @param {AhorrosModel} _data
     * @param {MovimientosService} _movimientosService
     * @param {LoginService} _loginService
     * @param {NgxSpinnerService} _ngxSpinnerService
     * @param {MatSnackBar} _matSnackBar,
     * 
     */
    constructor(
        private _dialogRef: MatDialogRef<AlertaComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any, 
        private _loginService: LoginService, 
        private _formBuilder: UntypedFormBuilder,
    ) {
      
    }

    ngOnInit(): void {
        this.socioExtendLogeado = this._loginService.obtenerSocioLogeado(); 
     
      
    }

    
    cancelar() {
        this._dialogRef.close();
        
    }
 
     
    addSlide() { 
    }
    
    removeSlide() {
     
    }
    
    slickInit(e) { 
    }
    
    breakpoint(e) { 
    }
    
    afterChange(e) { 
    }
    
    beforeChange(e) { 
    }
}
