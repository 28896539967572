// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `quick-panel {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  z-index: 99;
}
quick-panel .mat-slide-toggle-content {
  flex: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/components/quick-panel/quick-panel.component.scss","webpack://./../../../PROYECTO%20COOP/FRONT/web-frontend/src/app/layout/components/quick-panel/quick-panel.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,cAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,WAAA;ACCJ;ADCI;EACI,OAAA;ACCR","sourcesContent":["quick-panel {\r\n    display: flex;\r\n    flex-direction: column;\r\n    flex: 1 1 auto;\r\n    width: 280px;\r\n    min-width: 280px;\r\n    max-width: 280px;\r\n    z-index: 99;\r\n\r\n    .mat-slide-toggle-content {\r\n        flex: 1;\r\n    }\r\n}\r\n","quick-panel {\n  display: flex;\n  flex-direction: column;\n  flex: 1 1 auto;\n  width: 280px;\n  min-width: 280px;\n  max-width: 280px;\n  z-index: 99;\n}\nquick-panel .mat-slide-toggle-content {\n  flex: 1;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
