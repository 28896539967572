import { CODIGO_ERROR_NO_AUTENTICADO, MENSAJE_NO_AUTENTICADO, MENSAJE_NO_AUTENTICADO2 } from './../constants';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { LoginService } from './../../services/login.service';
import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private loginService: LoginService,
        private router: Router,
        private _matSnackBar: MatSnackBar,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {

        //   console.log(next);

        return next.handle(req).pipe(
            tap(data => {
                if (data instanceof HttpResponse) {

                    if (data.body.codigo != null) {
                        if (data.body.codigo == CODIGO_ERROR_NO_AUTENTICADO) {
                            this.loginService.cerrarSesion();
                            this._matSnackBar.open(data.body.mensaje, data.body.codigo, {
                                verticalPosition: 'bottom',
                                duration: 5000
                            });
                        }
                        /* this._matSnackBar.open(data.body.mensaje, data.body.codigo, {
                            verticalPosition: 'bottom',
                            duration: 5000
                        }); */
                    }

                    if (data.body != null && data.body.mensaje == MENSAJE_NO_AUTENTICADO2) {
                        this.loginService.cerrarSesion();
                        this._matSnackBar.open(data.body.mensaje, data.body.codigo, {
                            verticalPosition: 'bottom',
                            duration: 5000
                        });
                    }


                    // Para el FPS
                    if (data.body.data != null && data.body.data.mensaje == MENSAJE_NO_AUTENTICADO2) {
                        this.loginService.cerrarSesion();
                        this._matSnackBar.open(data.body.data.mensaje, data.body.data.codigo, {
                            verticalPosition: 'bottom',
                            duration: 5000
                        });
                    }



                    // Para los casos donde el backend no mande el codigo (el backend deberia corregir)
                    if (data.body != null) {
                        if (data.body.mensaje == MENSAJE_NO_AUTENTICADO) {
                            this.loginService.cerrarSesion();
                            this._matSnackBar.open(data.body.data.mensaje, data.body.data.codigo, {
                                verticalPosition: 'bottom',
                                duration: 5000
                            });
                        }
                    }




                    if (data.body.detalleRespuesta != null) {
                        if (data.body.detalleRespuesta == 'No Autenticado') {
                            this._matSnackBar.open(data.body.detalleRespuesta, 'JSR-105', {
                                verticalPosition: 'bottom',
                                duration: 5000
                            });
                            this.loginService.cerrarSesion();
                        } else {
                            this._matSnackBar.open(data.body.detalleRespuesta, data.body.respuesta, {
                                verticalPosition: 'bottom',
                                duration: 5000
                            });
                        }
                    }
                }

            }),

            catchError(e => {

                this._matSnackBar.open("Se perdió la conexión con el servidor", 'ERROR', {
                    verticalPosition: 'top',
                    duration: 6000
                });

                this.router.navigate(['/pages/login']);

                setTimeout(() => {

                    //   window.location.reload();
                }, 6000);



                return throwError(e);
            })
        );
    }

    /*isTokenExpirado(): boolean {
        const token = this.authService.token;
        const payload = this.authService.obtenerDatosToken(token);
        const now = new Date().getTime() / 1000;
        if (payload.exp < now) {
            return true;
        }
        return false;
    }*/
}