import { switchMap } from 'rxjs/operators';
import { GenericResponse } from './../models/generic-response';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
import { AhorrosResponse } from './../models/response/ahorros-response';
import { AhorrosRequest } from './../models/request/ahorros-request';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AhorrosService {
  
  /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {ConfigService} _configService
     */
  constructor(
    private _httpClient: HttpClient,
    private _configService:ConfigService
    ) { }



  listarCuentasAhorros(ahorrosRequest: AhorrosRequest): Observable<HttpResponse<AhorrosResponse>>{
    return this._configService.getConfig().pipe(switchMap((config:any) => {
      return this._httpClient.post<AhorrosResponse>(`${config.url_backend}/api/formateador/ahorros`, ahorrosRequest, { observe: 'response' })
      }))
  }


}
