import { formatearDinero } from './../../constants';
import { TipoCambioResponse } from './../../../models/response/tipo-cambio-response';
import { LoginService } from './../../../services/login.service';
import { TipoCambioRequest } from './../../../models/request/tipo-cambio-request';
import { UtilsService } from './../../../services/utils.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tipo-de-cambio',
  templateUrl: './tipo-de-cambio.component.html',
  styleUrls: ['./tipo-de-cambio.component.scss']
})
export class TipoDeCambioComponent implements OnInit {

  tipoCambioResponse:TipoCambioResponse = new TipoCambioResponse();

  constructor(
    private _utilsService:UtilsService,
    private _loginService:LoginService
  ) { }

  ngOnInit(): void {
    this.obtenerTipoCambio();
  }

  obtenerTipoCambio(){
    let tipoCambioRequest: TipoCambioRequest = new TipoCambioRequest(); 
    tipoCambioRequest.token = this._loginService.token
    this._utilsService.obtenerTipoCambioVigente(tipoCambioRequest).subscribe( (respuesta) =>{
      //console.log(respuesta)
      this.tipoCambioResponse = respuesta.body;
      this.tipoCambioResponse.tipoCambioCompraFormat =  formatearDinero(respuesta.body.tipoCambioCompra,'SOLES');
      this.tipoCambioResponse.tipoCambioVentaFormat =  formatearDinero(respuesta.body.tipoCambioVenta,'SOLES');
    } )
  }

}
